<template>
  <section data-name="ix-flows-peer-list"
    data-direction="direction">
    <div v-if="isLoading"
      id="loadingSpinner"
      v-loading="isLoading"
      :element-loading-text="$t('ix-flows.loading-peers')" />
    <div v-else>
      <el-form label-position="right"
        label-width="60px">
        <el-input v-model="filterText"
          :placeholder="$t('ix-flows.filter-by-name')"
          size="mini">
          <el-button slot="append"
            icon="el-icon-close"
            size="mini"
            @click="resetFilter" />
        </el-input>

        <el-form-item :label="$t('ix-flows.sort-by')">
          <el-select v-model="sortOrder"
            size="mini">
            <el-option v-for="item in sortOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
      <p>{{ $t('ix-flows.total-peers', { count: ixPeersFiltered.length }) }}</p>
      <ul>
        <li>
          <a href="#"
            :class="{ selected: selectedPeer === `top-five-${direction}` }"
            @click="selectTopFive">{{ $t('ix-flows.top-5', { direction }) }}</a>
        </li>
        <li>
          <hr>
        </li>
        <li v-for="peer in ixPeersFiltered"
          :key="peer.serviceUid"
          class="peer">
          <a href="#"
            :class="{ selected: selectedPeer && peer.serviceUid === selectedPeer.serviceUid }"
            @click="selectPeer(peer)">
            {{ peer.companyName }}
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'IxPeers',

  props: {
    direction: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      filterText: '',
      initalPeerSet: false,
      sortOrder: this.direction,
      sortOptions: [{ value: 'in', label: this.$t('ix-flows.volume-in') }, { value: 'out', label: this.$t('ix-flows.volume-out') }],
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['IXFlows/getLoadingState']
    },
    ixPeers() {
      return this.$store.getters['IXFlows/getIxPeers'].slice().sort((a, b) => {
        if (a.volume[this.sortOrder] < b.volume[this.sortOrder]) {
          return 1
        } else if (a.volume[this.sortOrder] > b.volume[this.sortOrder]) {
          return -1
        }
        return 0
      })
    },
    ixPeersFiltered() {
      if (this.filterText.length) {
        return this.ixPeers.filter(peer => {
          return JSON.stringify(peer)
            .toLowerCase()
            .includes(this.filterText.toLowerCase())
        })
      }
      return this.ixPeers
    },
    selectedPeer() {
      return this.$store.getters['IXFlows/getSelectedPeer'](this.direction)
    },
    ixPeersByDirection() {
      return this.$store.getters['IXFlows/getPeersByDirection'](this.direction)
    },
  },

  methods: {
    ...mapActions('IXFlows', ['fetchIXTelemetry', 'fetchTopTelemetry']),
    ...mapMutations('IXFlows', ['setSelectedPeer']),
    selectPeer(peer) {
      this.setSelectedPeer({ direction: this.direction, selected: peer })
      this.fetchIXTelemetry({ direction: this.direction })
    },
    selectTopFive() {
      this.setSelectedPeer({ direction: this.direction, selected: `top-five-${this.direction}` })
      const peers = this.ixPeersByDirection
        .slice(0, 5)
        .reverse()
      this.fetchTopTelemetry({ direction: this.direction, peers: peers })
    },
    resetFilter() {
      this.filterText = ''
    },
  },
}
</script>

<style lang="scss" scoped>
section {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 440px;
}

ul {
  overflow-y: auto;
  margin-left: 0;
  padding-left: 0;
  max-height: 300px;
  li {
    list-style: none;
    padding-bottom: 0.5rem;
  }
}

input {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  margin: 0.25rem 0;
}

.selected {
  font-weight: bold;
  color: #000000;
  text-decoration: none;
}
#loadingSpinner {
  position: absolute;
  top: 220px;
  left: 50%;
  width: 80px;
}
</style>
