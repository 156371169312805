<template>
  <section data-name="ix-flows-graph">
    <div v-if="!ixDataReady"
      id="loadingSpinner"
      v-loading="true"
      :element-loading-text="$t('looking-glass.loading')" />
    <div v-else-if="ixDataReady && !selectedPeer">
      {{ $t('ix-flows.select-peer') }}
    </div>
    <div v-else-if="ixDataReady">
      <template v-if="!isTopFiveSelected">
        <plotly data-name="ix-graph"
          :options="graphOptions"
          :data="graphData"
          :layout="graphLayout" />
      </template>
      <template v-else>
        <plotly v-if="topFiveTelemetryData.length"
          data-name="ix-graph"
          :options="graphOptions"
          :data="multiLineGraphData"
          :layout="graphLayout" />
      </template>
    </div>
    <div v-else>
      <p>{{ $t('ix-flows.no-data') }}</p>
    </div>
  </section>
</template>

<script>
import { DateTime } from 'luxon'
import plotly from '@statnett/vue-plotly'
import { mapActions } from 'vuex'

export default {
  name: 'IxFlowsGraph',

  components: {
    plotly: plotly,
  },

  props: {
    direction: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      graphOptions: {
        responsive: true,
        displaylogo: false,
        displayModeBar: false,
      },
      graphLayout: {
        title: {
          text: null,
          xref: 'paper',
          yref: 'paper',
          y: -1.0,
          x: 0.05,
          font: {
            size: 12,
          },
        },
        xaxis: {
          title: {
            text: null,
          },
          nticks: 10,
          type: 'date',
          hoverformat: '',
        },
        yaxis: {
          exponentformat: 'none',
          hoverformat: '.2f',
          title: '',
          rangemode: 'nonnegative',
        },
        margin: {
          l: 60,
          r: 60,
          t: 60,
          b: 90,
          autoResize: true,
        },
        showlegend: true,
        legend: {
          orientation: 'h',
        },
      },
    }
  },

  computed: {
    isTopFiveSelected() {
      return typeof this.selectedPeer === 'string' ? true : false
    },
    selectedPeer() {
      return this.$store.getters['IXFlows/getSelectedPeer'](this.direction)
    },
    selectedDates() {
      return this.$store.getters['IXFlows/getSelectedDates']
    },
    telemetryData() {
      return this.$store.getters['IXFlows/getTelemetryData'][this.direction]
    },
    topFiveTelemetryData() {
      return this.$store.getters['IXFlows/getTopFiveTelemetry'](this.direction)
    },
    ixDataReady() {
      return this.$store.getters['IXFlows/getReadyStatus'](this.direction)
    },
    selectedFlowType() {
      return this.$store.getters['IXFlows/getSelectedFlowType']
    },
    graphData() {
      const colours = ['rgb(233,31,47)', 'rgb(6,121,182)']
      const directions = ['In', 'Out']
      const directionStrings = [this.$t('graph.in'), this.$t('graph.out')]
      const dataTypes = this.selectedFlowType.outputUnit.name

      return colours.map((color, idx) => {
        let _x = []
        let _y = []

        const dirIdx = this.telemetryData?.findIndex(data => {
          return data.subtype.toLowerCase() === directions[idx].toLowerCase()
        }) ?? -1

        if (dirIdx !== -1) {
          this.telemetryData[dirIdx].samples.forEach(sample => {
            _x.push(sample[0])
            _y.push(sample[1])
          })
        }

        return {
          x: _x,
          y: _y,
          name: directionStrings[idx],
          mode: 'lines',
          line: {
            color: color,
          },
          connectgaps: false,
          hovertemplate: `%{y:.2f}${dataTypes}`,
        }
      })
    },
    multiLineGraphData() {
      const colours = ['rgb(199,233,180)', 'rgb(127,205,187)', 'rgb(65,182,196)', 'rgb(44,127,184)', 'rgb(37,52,148)']
      const dataTypes = this.selectedFlowType.outputUnit.name

      return colours.map((color, idx) => {
        let _x = []
        let _y = []

        const dirIdx = this.topFiveTelemetryData[idx].data?.findIndex(data => {
          return data.subtype.toLowerCase() === this.direction.toLowerCase()
        }) ?? -1

        if (dirIdx !== -1) {
          this.topFiveTelemetryData[idx].data[dirIdx].samples.forEach(sample => {
            _x.push(sample[0])
            _y.push(sample[1])
          })
        }

        return {
          x: _x,
          y: _y,
          name: this.topFiveTelemetryData[idx].peer,
          mode: 'lines',
          line: {
            color: color,
          },
          connectgaps: false,
          hovertemplate: `%{y:.2f}${dataTypes}`,
          stackgroup: 'top-five',
        }
      })
    },
    graphTitle() {
      if (!this.isTopFiveSelected) {
        return `${this.selectedPeer.companyName} (${this.selectedPeer.asn})
        <br />${this.selectedPeer.peerAddresses[0].address}
        <br />${this.selectedPeer.peerAddresses[1].address}
        <br />${this.graphDateRange}\
        `
      }
      return `${this.$t('ix-flows.top-5', { direction: this.direction })} <br />${this.graphDateRange}`
    },
    graphDateRange() {
      const start = DateTime.fromMillis(this.selectedDates.start).toFormat('dd/LL/yy')
      const end = DateTime.fromMillis(this.selectedDates.end).toFormat('dd/LL/yy')
      return `${start} - ${end}`
    },
  },

  destroyed() {
    this.resetIXFlows()
  },

  beforeUpdate() {
    if (this.selectedPeer) {
      this.graphLayout.title.text = this.graphTitle
      this.graphLayout.yaxis.title = this.selectedFlowType.outputUnit.name
      this.graphLayout.yaxis.typeMeasurement = this.selectedFlowType.outputUnit.name
    }
  },

  methods: {
    ...mapActions('IXFlows', ['resetIXFlows']),
  },

}
</script>

<style scoped>
section {
  height: 100%;
  position: relative;
}
#loadingSpinner {
  position: absolute;
  top: 220px;
  left: 50%;
  width: 80px;
}
</style>
