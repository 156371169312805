<template>
  <span data-name="ix-date-select">
    <label class="sr-only">{{ $t('ix-flows.date-select') }}</label>
    <el-dropdown @command="selectDateRange">
      <el-button>{{ selectedDateRange.title }}
        <i class="el-icon-arrow-down el-icon--right"
          aria-hidden="true" />
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="option in dateRangeOptions"
          :key="option.title"
          :command="option">
          <span>
            {{ option.title }}
          </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </span>
</template>

<script>
import { DateTime } from 'luxon'
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'IxFlowsDateSelect',

  data() {
    return {
      dateRangeOptions: [
        { title: this.$t('graph.last-12-h'), value: 12, period: 'hours' },
        { title: this.$t('graph.last-24-h'), value: 24, period: 'hours' },
        { title: this.$t('graph.last-7-d'), value: 7, period: 'days' },
      ],
      selectedDateRange: null,
    }
  },

  created() {
    this.selectDateRange(this.dateRangeOptions[1])
  },

  methods: {
    ...mapMutations('IXFlows', ['setDateRange']),
    ...mapActions('IXFlows', ['updateIXTelemetry']),
    selectDateRange(range) {
      this.selectedDateRange = range
      const startDate = DateTime.now()
        .minus({ [this.selectedDateRange.period]: this.selectedDateRange.value })
        .toMillis()
      const endDate = DateTime.now().toMillis()
      this.setDateRange({ startDate: startDate, endDate: endDate })
      this.updateIXTelemetry()
    },
  },
}
</script>

<style lang="scss" scoped>
span {
  width: 200px;
  margin-left: 0.5rem;
}
label {
  padding-right: 1rem;
}
select {
  width: 200px;
}
</style>
