<template>
  <section data-name="ix-flows-ip">
    <h4>{{ $t('connections.ip-addresses') }} - {{ selectedIXProduct.productName }}</h4>
    <ul>
      <li v-for="ip in selectedIXProduct.ip_address"
        :key="ip.id">
        {{ ip.address }}
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'IxFlowIp',
  computed: {
    selectedIXProduct() {
      return this.$store.getters['IXFlows/getSelectedProduct']
    },
  },
}
</script>

<style scoped>
section {
  text-align: right;
  height: 50px;
  margin-right: 1rem;
}

h4 {
  font-size: 1.1rem;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  display: inline-block;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  border-right: 1px solid #ccc;
}
li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
</style>
