<template>
  <span data-name="ix-flows-service-select">
    <label>{{ $t('connections.ix-service') }}</label>
    <el-dropdown v-if="selectedService"
      @command="selectIXHandler">
      <el-button>{{ selectedService.productName }}
        <i class="el-icon-arrow-down el-icon--right"
          aria-hidden="true" />
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="(service, idx) in ixServices"
            :key="`${service.productName}-${idx}`"
            class="serviceDetails"
            :command="service">
            <span>
              <strong>{{ service.productName }}</strong>
              <br>
              {{ service.ip_address[0].address }}<br>
              {{ service.ip_address[1].address }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </span>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'IxServiceSelect',

  computed: {
    ...mapGetters('Services', ['myMappedIxs']),
    ...mapGetters('IXFlows', ['getSelectedProduct']),
    ixServices() {
      return this.myMappedIxs.slice().sort((a, b) => {
        if (a.productName > b.productName) {
          return 1
        } else if (a.productName < b.productName) {
          return -1
        }
        return 0
      })
    },
    selectedService() {
      return this.getSelectedProduct
    },
  },

  mounted() {
    if (typeof this.$route.params.productUid !== 'undefined') {
      const service = this.ixServices.find(s => {
        return s.productUid === this.$route.params.productUid
      })
      service ? this.selectIXHandler(service) : this.selectIXHandler(this.ixServices[0])
    } else {
      this.selectIXHandler(this.ixServices[0])
    }
  },

  methods: {
    ...mapActions('IXFlows', ['fetchPeers', 'resetLoadReadyStatus']),
    ...mapMutations('IXFlows', ['setSelectedProduct', 'clearSelectedPeers']),
    selectIXHandler(service) {
      this.clearSelectedPeers()
      this.resetLoadReadyStatus()
      this.setSelectedProduct(service)
      this.fetchPeers()
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  padding-right: 1rem;
}

.serviceDetails {
  line-height: 1.8rem;
  margin-bottom: 0.5rem;
  span {
    display: inline-block;
    padding: 0.75rem 0;
  }
}
</style>
