<template>
  <section data-name="ix-flows-view">
    <div v-if="!locationsReady || !servicesReady"
      class="el-row">
      <div class="el-col-24">
        <div id="loadingText"
          v-loading="true"
          :element-loading-text="$t('services.loading-resources')" />
      </div>
    </div>
    <div v-else-if="isService"
      class="noService">
      <h4>{{ $t('ix-flows.no-ix-found') }}</h4>
      {{ $t('ix-flows.no-ix-message') }}
    </div>
    <div v-else-if="locationsReady"
      class="el-row">
      <header id="ix-flows-header">
        <div class="el-col-4">
          <h2>{{ $t('menu.ix-telemetry') }}</h2>
        </div>
        <div class="el-col-20">
          <el-form data-name="ix-menu"
            :inline="true">
            <ix-service-select />
            <ix-flows-date-select />
            <ix-flow-types-select />
          </el-form>
        </div>
      </header>
      <template v-if="selectedIXProduct">
        <div class="ix-flow-graph el-col-24">
          <ix-ip />
          <el-card>
            <div v-if="dataInError"
              data-name="error-message">
              <h4>{{ $t('services.loading-issue') }}</h4>
              <p>{{ dataInError }}</p>
            </div>
            <template v-else>
              <div class="el-col-4">
                <ix-peers direction="in" />
              </div>
              <div class="el-col-20">
                <ix-flows-graph direction="in" />
              </div>
            </template>
          </el-card>
        </div>
        <div class="ix-flow-graph el-col-24">
          <el-card>
            <div v-if="dataOutError"
              data-name="error-message">
              <h3>{{ $t('services.loading-issue') }}</h3>
              <p>{{ dataOutError }}</p>
            </div>
            <template v-else>
              <div class="el-col-4">
                <ix-peers direction="out" />
              </div>
              <div class="el-col-20">
                <ix-flows-graph direction="out" />
              </div>
            </template>
          </el-card>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import IXServiceSelect from '@/components/ix-flows/IXServiceSelect'
import IXFlowsGraph from '@/components/ix-flows/IXFlowsGraph'
import IXPeers from '@/components/ix-flows/IXPeers'
import IXFlowTypesSelect from '@/components/ix-flows/IXFlowTypesSelect'
import IXFlowsDateSelect from '@/components/ix-flows/IXFlowsDateSelect'
import IXFlowIP from '@/components/ix-flows/IXFlowsIP'

export default {
  name: 'IxFlowView',

  components: {
    'ix-service-select': IXServiceSelect,
    'ix-flows-graph': IXFlowsGraph,
    'ix-peers': IXPeers,
    'ix-flow-types-select': IXFlowTypesSelect,
    'ix-flows-date-select': IXFlowsDateSelect,
    'ix-ip': IXFlowIP,
  },

  computed: {
    ...mapState('Services', ['servicesReady', 'locationsReady']),
    ...mapState('IXFlows', ['selectedIXProduct', 'errors']),
    ...mapGetters('Services', ['myMappedIxs']),
    dataInError() {
      return this.errors.in
    },
    dataOutError() {
      return this.errors.out
    },
    isService() {
      return this.locationsReady && this.servicesReady && this.myMappedIxs.length === 0
    },
  },
}
</script>


<style scoped lang="scss">
.ix-flow-graph {
  padding: 1rem;
}

#ix-flows-header {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: var(--mp-sub-header-background-color);
  height: 40px;
  padding: 2rem;
  border-bottom: 1px solid var(--card-border-color);
  // For IE 10 & 11
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: static;
  }

  h2 {
    margin-bottom: 2rem;
    font-weight: 300;
  }
}

form {
  float: right;
}

#loadingText {
  padding-top: 25%;
}

.noService {
  margin: 5% auto;
  text-align: center;
  width: 30%;
  padding: 3rem;
  border: 0.5px solid var(--color-info);
  border-radius: var(--border-radius-base);
}
</style>
