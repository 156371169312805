<template>
  <span data-name="ix-flows-data-types">
    <label class="sr-only">{{ $t('ix-flows.flow-type') }}</label>
    <el-dropdown v-if="ixTypes"
      @command="selectType">
      <el-button>{{ selectedFlowType.name }}
        <i class="el-icon-arrow-down el-icon--right"
          aria-hidden="true" />
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="type in ixTypes.metrics"
            :key="type.name"
            :command="type">
            <span>
              {{ type.name }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </span>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'IxFlowTypesSelect',
  computed: {
    ixTypes() {
      return this.$store.getters['IXFlows/getFlowTypes']
    },
    selectedFlowType() {
      return this.$store.getters['IXFlows/getSelectedFlowType']
    },
  },

  created() {
    this.fetchFlowTypes()
  },

  methods: {
    ...mapActions('IXFlows', ['fetchFlowTypes', 'updateIXTelemetry']),
    ...mapMutations('IXFlows', ['setSelectedFlowType']),
    selectType(type) {
      this.setSelectedFlowType({ selected: type })
      this.updateIXTelemetry()
    },
  },

}
</script>

<style lang="scss" scoped>
span {
  width: 150px;
  margin-left: 0.5rem;
}
label {
  padding-right: 1rem;
}
select {
  width: 150px;
}
</style>
